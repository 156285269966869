.bg-anim {
  animation: anim 15s infinite;
  font-weight: bolder;
}

@keyframes anim {
  0% {
    background-color: rgba(0, 183, 255, 0.9);
    color: white;
  }
  20% {
    background-color: rgba(0, 185, 0, 0.9);
    color: white;
  }
  40% {
    background-color: rgba(255, 165, 0, 0.9);
    color: white;
  }
  60% {
    background-color: rgba(255, 0, 0, 0.9);
    color: white;
  }
  80% {
    background-color: rgba(255, 0, 234, 0.9);
    color: white;
  }
  100% {
    background-color: rgba(0, 183, 255, 0.9);
    color: white;
  }
}
