.hover-zoom:hover {
  transform: scale(1.2);
  z-index: 2;
  
}

.hover-spin:hover {
  animation: spin 0.5s linear;
}

.hover-blink:hover {
  animation: fading 0.5s linear;
}

.hover-fly:hover {
  position: relative;
  animation: fly 0.5s linear;
}

.hover-fly-infinite:hover {
  position: relative;
  animation: fly 1.5s infinite linear;
}

.bg-theme{
    background-image: radial-gradient(circle, rgb(61, 23, 107), rgb(42, 9, 89), #222)
}

.spin {
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes fly {
  0% {
    bottom: 0px;
  }
  50% {
    bottom: -8px;
  }
  100% {
    bottom: 0px;
  }
}

@keyframes fading {
    0% {
      opacity: 0; }
    50% {
      opacity: 1; }
    100% {
      opacity: 0; } }