.marquee {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin: auto;
    padding: 16px 4px;
  }
  
  .marquee--inner {
    display: flex;
    width: fit-content;
    position: relative;
  }
  
  .marquee--inner:hover {
    animation-play-state: paused;
  }
  
  .marquee--inner span {
    display: flex;
    width: 50%;
  }
  .marquee-image {
    width: 200px;
    height: 80px;
    padding: 0px 4px;
  }
  
  @keyframes marquee {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }
  
  @media screen and (max-width: 600px) {
    .marquee--inner {
      animation: marquee 15s linear infinite;
    }
  }
  @media screen and (min-width: 600px) {
    .marquee--inner {
      animation: marquee 20s linear infinite;
    }
  }
  